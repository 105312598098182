import React, { useEffect, useState } from 'react';
import {
    setGuaranteedFee, setGuaranteedFeeYou
} from "../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";
import Error from "../../../Error/Error";
import classes from "../../Gender/Gender.module.scss";
import Radio from "../../../UI/Radio/Radio";

const GuaranteedFeeComp = ( props ) => {
    const [checked, setChecked] = useState('');
    const [error, setError] = useState(false);

    const radios = [
        {
            value: 'Yes'
        },
        {
            value: 'No'
        }
    ];

    const changeValHandler = (e) => {
        const val = e.target.value;
        const single = !props.isSpouse;
        setChecked(val);
        props.setGuaranteedFeeYou(val);
        props.setGuaranteedFee(single)
    };

    useEffect(() => {
        const single = !props.isSpouse;
        props.setGuaranteedFee(single);

        setChecked(props.guaranteedFeeYou);
        setError(!props.guaranteedFeeYou)
    }, [props, error]);
    return (
        <React.Fragment>
            {
                error &&
                <Error
                    message='Selection required'
                    style={{
                        left:'50%',
                        transform: 'translateX(-50%)',
                        marginLeft:0
                    }}
                />
            }

            {
                radios.map((radio, index) => {
                    return (
                        <div key={index} className = {classes.ContentRow__card__radio}>
                             <Radio
                                 changed={changeValHandler}
                                 name='guaranteedFeeYou'
                                 value={radio.value}
                                 isSelected={ checked === radio.value }
                             />
                         </div>
                    )
                })
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        guaranteedFeeYou: state.investorProfile.guaranteedFeeYou,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setGuaranteedFeeYou: (val)=> dispatch(setGuaranteedFeeYou(val)),
        setGuaranteedFee: (single)=> dispatch(setGuaranteedFee(single)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuaranteedFeeComp);