import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import classes from "./shortTermLosses/shortTermLosses.module.scss";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import GuaranteedFeeComp from "../../../components/ContentRow/Step2/GuaranteedFee/GuaranteedFeeComp";
import GuaranteedFeeSpouseComp
    from "../../../components/ContentRow/Step2/GuaranteedFee/GuaranteedFeeSpouseComp";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const GuaranteedFee = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'Would you like to have some or all of your income in retirement guaranteed for a slightly higher fee?',
    };

    const title = "Guaranteed income in retirement";

    const next = (props.guaranteedFeeYou === "Yes" || (props.guaranteedFeeSpouse === 'Yes' && props.isSpouse))
        ? retirementCalcLinks.guaranteedPercentage
        : retirementCalcLinks.profile;

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(4/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='guaranteedFee'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'you'}>
                    <div className={classes.RadioWrap}>
                        <GuaranteedFeeComp/>
                    </div>
                </InnerLayoutStepContent>

                {
                    props.isSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <div className={classes.RadioWrap}>
                            <GuaranteedFeeSpouseComp/>
                        </div>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={props.isCompletedGuaranteedFee}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isCompletedGuaranteedFee: state.investorProfile.isCompletedGuaranteedFee,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        guaranteedFeeYou: state.investorProfile.guaranteedFeeYou,
        guaranteedFeeSpouse: state.investorProfile.guaranteedFeeSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuaranteedFee);